<template>
  <div>
    <VueMultiselect
      v-model="selected"
      :tag-placeholder="tagPlaceholder"
      :clear-on-select="false"
      :placeholder="placeholder"
      :label="label"
      :track-by="trackBy"
      :options="options"
      :name="selectName"
      :multiple="true"
      :taggable="taggable"
      @tag="addedTag"
      @select="selectedOption"
      @remove="deleteSelected"
      :show-no-results="false"
      @search-change="searchChange"
      :loading="isLoading"
    >
      <template #selection="selectionProps">
        <span
          v-if="selectionProps.values.length"
          class="multiselect__placeholder"
        >
          {{placeholder}}
        </span>
      </template>
      <template #noResult>{{noResultsFoundMessage}}</template>
    </VueMultiselect>
    <div class="mt-2">
      <slot name="tag_header"></slot>
      <ul class="flex flex-wrap mt-6">
        <li v-for="(tag, index) in selected" :key="tag.code">
            <span class="multiselect__tag !rounded-full !bg-accent/60 !text-primary text-sm font-medium !py-2 !px-3 !pr-6 flex items-center">
              <span>{{tag.name}}</span><i tabindex="1" @click="deleteSelected(tag.code)" class="multiselect__tag-icon flex items-center after:!text-primary after:ml-1"></i>
            </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">

import VueMultiselect from 'vue-multiselect'
import { ref, watchEffect } from "vue";

const emit = defineEmits(['search', 'addedTag', 'selected', 'deleteSelected', 'selectedOption']);

const props = defineProps({
  options: {
    default: [],
    type: Array
  },
  placeholder: {
    default: 'Search or add a tag',
    type: String,
  },
  tagPlaceholder: {
    default: 'Add this as new tag',
    type: String,
  },
  trackBy: {
    default: 'code',
    type: String
  },
  label: {
    default: 'name',
    type: String,
  },
  noResultsFoundMessage: {
    default: 'Oops! No elements found. Consider changing the search query.',
    type: String
  },
  isLoading: {
    default: false,
    type: Boolean
  },
  selectedOptions: {
    default: [] as Tag[],
    type: Array as () => Tag[],
  },
  selectName: {
      default: '',
      type: String
  },
  taggable: {
    default: true,
    type: Boolean
  }
});

const selected = ref<Array<Tag>>([]);

watchEffect(() => {
  if (props.selectedOptions.length > 0) {
    selected.value = props.selectedOptions;
  }
});
function addedTag(newTag: Tag) {
  emit('addedTag', newTag);
}

function selectedOption(selected: Tag) {
  emit('selectedOption', selected);
}

function deleteSelected(code: number) {
  emit('deleteSelected', code);
}

function searchChange(query: string) {
  emit('search', query);
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect .multiselect__tags {
  @apply text-primary;
  @apply text-sm;
  @apply px-4;
  @apply py-3;
  @apply bg-grey;
  @apply border;
  @apply border-transparent;
  @apply rounded-t-full;
  @apply rounded-b-full;
}

.multiselect--above.multiselect--active .multiselect__tags {
  @apply rounded-t-full;
  @apply rounded-b-full;
}

.multiselect--active:not(.multiselect--above) .multiselect__tags {
  @apply rounded-t-full;
  @apply rounded-b-full;
}

.multiselect__input {
  @apply bg-transparent;
}

.multiselect__content-wrapper {
  @apply rounded-full;
}

.multiselect__spinner {
  @apply bg-grey;
  right: 15px;
  top: 4px;
}

.multiselect__spinner::before, .multiselect__spinner::after {
  border-color: #52CC86 transparent transparent
}
</style>
